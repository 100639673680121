import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const FeatureGrid = ({ gridItems }) => (
  <>
    {gridItems.map((item) => (
      <div key={item.title} className="column is-12">
        <div>
          <div className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info">
            {item.title}
          </div>
          <p className="is-size-5">{item.description}</p>
        </div>
        <Fade bottom>
          <div
            style={{
              // width: '500px',
              //   display: 'inline-block',
              margin: '8px auto 16px auto',
            }}
          >
            <PreviewCompatibleImage imageInfo={item} />
          </div>
        </Fade>
      </div>
    ))}
  </>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
