import React from 'react';
import { Link } from 'gatsby';
// import Img from 'gatsby-image';
import styled from '@emotion/styled';
import { ParallaxBanner } from 'react-scroll-parallax';
// import WaitingList from './WaitingList';
import icons from '../img/icons.png';

const Overlay = styled.div`
  width: 100%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

function CTA(props) {
  // const { title, subtitle, link } = props;

  const overlay = (
    <Overlay>
      <div className="columns image-overlay is-vcentered">
        <div className="column is-1"></div>
        <div className={'column is-5'}>
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen is-family-secondary has-text-left has-text-white"
            style={{
              lineHeight: '1',
            }}
          >
            We have launched!
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen has-text-left has-text-white"
            style={{
              paddingTop: '2rem',
            }}
          >
            Dont miss out on how simple and organized we make the CPEU process. The best tool to
            find CPEUs that match your learning plan and more.
          </h3>
        </div>
        <div className="column is-4 has-text-centered">
          {/* <WaitingList large location={'CTA'} url={'/'} /> */}
          <Link className="button is-primary is-large" to="/signup">
            Join Today!
          </Link>
          <div className="column is-2"></div>
        </div>
      </div>
    </Overlay>
  );
  return (
    <ParallaxBanner
      layers={[
        {
          image: icons,
          amount: 0.2,
        },
      ]}
      style={{
        backgroundPosition: `top`,
        backgroundSize: 'cover',
        height: '450px',
        width: '100%',
      }}
    >
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      >
        {overlay}
      </div>
    </ParallaxBanner>
  );
}

export default CTA;
