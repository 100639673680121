import React from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';
import blue from '../img/blue.png';

const Testimonials = ({ testimonials }) => (
  <section
    className="section testimonial-section"
    style={{
      backgroundImage: `url(${blue})`,
      backgroundPosition: `top`,
      backgroundSize: 'cover',
      // height: '450px',
      width: '100%',
    }}
  >
    {testimonials.map((testimonial) => (
      <div className="columns has-background-white testimonial is-multiline" key={v4()}>
        <div className="column is-narrow">
          <div className="image is-rounded">
            <img
              alt="RD testimonial"
              className="image is-rounded is-128x128"
              src={testimonial.headshot.childImageSharp.fluid.src}
              style={{
                margin: 'auto',
              }}
            />
          </div>
        </div>
        <div className="column">
          <p className="is-size-5 has-text-grey">{testimonial.quote}</p>
          <div className="has-text-black">{testimonial.author}</div>
          <div className="subtitle is-size-7 has-text-grey">
            <em>{testimonial.credentials}</em>
          </div>
        </div>
      </div>
    ))}
  </section>
);

Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
      credentials: PropTypes.string,
      headshot: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    })
  ),
};

export default Testimonials;
