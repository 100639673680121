import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, withPrefix } from 'gatsby';

import BlogRoll from '../components/BlogRoll';
// import CTA from '../components/CTA';
import CTA from '../components/CTAParallax';
import Features from '../components/Features';
import Hero from '../components/Hero';
// import Hero from '../components/HeroParallax';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Testimonials from '../components/Testimonials';
import Content, { HTMLContent } from '../components/Content';

// import icons from '../img/icons.png';

export const IndexPageTemplate = ({
  image,
  title,
  subtitle,
  mainpitch,
  mainbody,
  intro,
  contentComponent,
  testimonials,
  // location,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <div>
      <Hero
        image={!image.childImageSharp ? image : image.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
        link={true}
        location={'Hero'}
        url={'/'}
      />
      {/* {console.log('index props.location', location)} */}
      <section className="section section--gradient">
        <div className="container">
          {/* <div className="section"> */}
          <div className="columns">
            <div className="column is-10 is-offset-1">
              {/* <div className="content"> */}
              <div className="content">
                <div className="tile">
                  <h1 className="title is-size-2 is-family-secondary has-text-info">{mainpitch}</h1>
                </div>
                <div className="tile">
                  <PageContent className="content is-size-5" content={mainbody} />
                </div>
              </div>
              {/* <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info">
                      {heading}
                    </h3>
                    <p className="is-size-5">{description}</p>
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info">
                      {intro.heading}
                    </h3>
                    <p className="is-size-5">{intro.description}</p>
                  </div>
                </div> */}
              <div className="columns is-multiline">
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </section>
      <Testimonials testimonials={testimonials} />
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="column is-12">
              <h3 className="has-text-weight-semibold is-size-2 is-family-secondary has-text-info">
                Latest stories
              </h3>
              <BlogRoll />
              <div className="column is-12 has-text-centered field is-grouped">
                <Link className="button is-primary is-outlined" to="/blog">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CTA />
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  mainpitch: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  // testimonials: PropTypes.shape({
  //   quote: PropTypes.string,
  //   author: PropTypes.string,
  //   credentials: PropTypes.string,
  //   headshot: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  // }),
  testimonials: PropTypes.array,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout customSEO>
      <SEO
        title={'CPEU.org is the best place for RDs to find CPEUs.'}
        description={
          'Registered Dietitians can effortlessly filter all CDR accredited CPEUs that match your Professional Development Portfolio and your budget.'
        }
        // banner={`${withPrefix('/')}img/og-image.jpg`}
      />
      <IndexPageTemplate
        contentComponent={HTMLContent}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        mainpitch={frontmatter.mainpitch}
        mainbody={data.markdownRemark.html}
        intro={frontmatter.intro}
        testimonials={frontmatter.testimonials}
        // location={location}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        subtitle
        mainpitch
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description
          }
        }
        testimonials {
          author
          credentials
          quote
          headshot {
            childImageSharp {
              fluid(maxWidth: 96, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      html
    }
  }
`;
